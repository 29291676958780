// 评级
export const Grades = [
  {
    value: 'S+',
    text: 'S+'
  },
  {
    value: 'S',
    text: 'S'
  },
  {
    value: 'A',
    text: 'A'
  },
  {
    value: 'B',
    text: 'B'
  },
  {
    value: 'C',
    text: 'C'
  }
]
// 版权
export const Copyrights = [
  {
    value: 1,
    text: '有效'
  },
  {
    value: 2,
    text: '过期'
  }
]
// 视频比例
export const VideoScales = [
  {
    value: 1,
    text: '竖版'
  },
  {
    value: 2,
    text: '横版'
  },
  {
    value: 3,
    text: '横转竖'
  }
]
// 结算方式
export const CashTypes = [
  {
    value: 1,
    text: '一口价'
  },
  {
    value: 2,
    text: '底价+分成'
  }
]
// 上架状态
export const OnShelfStatusOptions = [
  {
    value: 1,
    text: '在架'
  },
  {
    value: 0,
    text: '下架'
  }
]
// 版权期限
export const CopyrightTermTypes = [
  {
    value: 1,
    text: '永久'
  },
  {
    value: 2,
    text: '有效期'
  }
]
// 独家授权类型
export const ExclusiveTypes = [
  {
    value: 1,
    text: '独家'
  },
  {
    value: 0,
    text: '非独家'
  }
]
// 用户退款 - 退款状态
export const RefundStatus = [
  {
    value: 0,
    text: '待退款'
  },
  {
    value: 1,
    text: '退款成功'
  },
  {
    value: 2,
    text: '退款失败'
  }
]
// 用户退款 - 支付渠道列表
export const RefundPayChannels = [
  {
    value: 1,
    text: '微信支付'
  },
  {
    value: 4,
    text: '微信H5支付'
  },
  {
    value: 8,
    text: '抖音担保支付'
  },
  {
    value: 9,
    text: '快手支付'
  },
  {
    value: 10,
    text: '微信虚拟支付'
  },
  {
    value: 11,
    text: '抖音IOS支付'
  },
  {
    value: 12,
    text: '抖音通用支付'
  }
]
// 配置类型
export const ConfigTypes = [
  {
    value: 1,
    text: '支付宝'
  },
  {
    value: 2,
    text: '微信'
  },
  {
    value: 3,
    text: '抖音'
  },
  {
    value: 4,
    text: '快手'
  },
  {
    value: 5,
    text: '企业微信'
  },
  {
    value: 6,
    text: '杉德'
  },
  {
    value: 7,
    text: '小红书'
  }
]
// 支付渠道列表
export const PayChannels = [
  {
    value: 1,
    text: '微信小程序支付'
  },
  {
    value: 2,
    text: '支付宝APP支付'
  },
  {
    value: 3,
    text: '微信APP支付'
  },
  {
    value: 4,
    text: '微信外部H5支付'
  },
  {
    value: 5,
    text: '支付宝H5支付'
  },
  {
    value: 6,
    text: '杉德支付'
  },
  {
    value: 7,
    text: '微信内部H5支付'
  },
  {
    value: 8,
    text: '抖音担保支付'
  },
  {
    value: 9,
    text: '快手支付'
  },
  {
    value: 10,
    text: '微信虚拟支付'
  },
  {
    value: 11,
    text: '抖音IOS支付'
  },
  {
    value: 12,
    text: '抖音支付'
  },
  {
    value: 13,
    text: '快手IOS支付'
  },
  {
    value: 14,
    text: '小红书担保支付'
  },
  {
    value: 15,
    text: '微信PC支付'
  }
]
// 系统统一分类配置
export const ClassifySettings = {
  // 剧情类型
  type: 'type',
  // 剧情标签
  tag: 'tag'
}
// 前台配置source
export const FrontSource = {
  // banner
  banner: 'banner',
  // 榜单
  top: 'top',
  // 分类板块
  module: 'module',
  // 收费
  charge: 'charge',
  // 虚拟币任务
  task: 'task',
  //任务banner
  task_banner: 'task_banner',
  //特价
  special_price: 'special_price',
  //站外任务
  other_task: 'other_task',
  //首页二级导航
  nav: 'nav',
  //图标导航
  icon_nav: 'icon_nav',
  //用户中心banner
  user_banner: 'user_banner',
}
// 抖小审核结果
export const AuditStatusTt = [
  {
    value: 1,
    text: '已上线'
  },
  {
    value: 2,
    text: '已通过'
  },
  {
    value: 3,
    text: '未通过'
  },
  {
    value: 4,
    text: '审核中'
  },
  {
    value: 5,
    text: '待提审'
  }
]
// 结算结果
export const SettlementResults = [
  {
    value: 0,
    text: '打款中'
  },
  {
    value: 1,
    text: '已打款'
  },
  {
    value: 2,
    text: '打款失败'
  }
]
// 封面角标
export const CornerMasks = [
  {
    value: 1,
    text: '独播'
  },
  {
    value: 2,
    text: '新剧'
  },
  {
    value: 3,
    text: '火爆'
  }
]
// 片源
export const FilmTypes = [
  {
    value: 1,
    text: '自制'
  },
  {
    value: 2,
    text: '外采成片'
  }
]
// 备案
export const FilingTypes = [
  {
    value: 0,
    text: '否'
  },
  {
    value: 1,
    text: '是'
  }
]
// 着陆页
export const LandingTypes = [
  {
    value: 1,
    text: '播放页'
  },
  {
    value: 2,
    text: '首页'
  }
]
// 结算周期
export const SettlementCycle = [
  {
    value: 1,
    text: '日结'
  },
  {
    value: 2,
    text: '周结'
  },
  {
    value: 3,
    text: '月结'
  }
]
// 客户端类型（主要用这套）
export const Platforms = [
  {
    value: 1,
    text: '抖音'
  },
  {
    value: 2,
    text: '快手'
  },
  {
    value: 3,
    text: '微信'
  },
  {
    value: 4,
    text: '快应用'
  },
  {
    value: 5,
    text: 'H5'
  },
  // {
  //   value: 6,
  //   text: '安卓'
  // },
  {
    value: 7,
    text: 'PC'
  },
  {
    value: 8,
    text: '小红书'
  }
]
// 客户端（这是老的，但代码内还在使用，小程序前端好像也是这套）
export const ClientTypes = [
  {
    value: 1,
    text: '微信小程序',
    channel: [1, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
  },
  {
    value: 2,
    text: '抖音小程序',
    channel: [1, 4, 11]
  },
  {
    value: 3,
    text: '快手小程序',
    channel: [8, 11]
  },
  {
    value: 4,
    text: '快应用',
    channel: [1, 5, 7]
  },
  {
    value: 5,
    text: 'H5',
    channel: [1, 4, 11]
  },
  // {
  //   value: 7,
  //   text: 'PC',
  //   channel: []
  // },
  {
    value: 8,
    text: '小红书小程序',
    channel: []
  }
]
// 推广渠道
export const ChannelTypes = [
  {
    value: 1,
    text: '巨量引擎',
    platform: [1, 3, 4, 5]
  },
  // {
  //   value: 2,
  //   text: '磁力引擎',
  //   platform: []
  // },
  {
    value: 3,
    text: '广点通',
    platform: [3]
  },
  {
    value: 4,
    text: '其他',
    platform: [1, 3, 5]
  },
  {
    value: 5,
    text: 'OPPO',
    platform: [3, 4]
  },
  // {
  //   value: 6,
  //   text: '推啊',
  //   platform: []
  // },
  {
    value: 7,
    text: 'VIVO',
    platform: [3, 4]
  },
  {
    value: 8,
    // text: '快手'
    text: '磁力引擎',
    platform: [3]
  },
  {
    value: 9,
    text: '爱奇艺',
    platform: [3]
  },
  {
    value: 10,
    text: '百度',
    platform: [3]
  },
  {
    value: 11,
    text: '达人推广',
    platform: [1, 2, 3, 5]
  },
  {
    value: 12,
    text: '小米',
    platform: [3]
  },
  {
    value: 13,
    text: '优酷',
    platform: [3]
  },
  {
    value: 14,
    text: 'B站',
    platform: [3]
  },
  {
    value: 15,
    text: '微博',
    platform: [3]
  },
  {
    value: 16,
    text: 'Wifi万能钥匙',
    platform: [3]
  },
  {
    value: 17,
    text: 'UC',
    platform: [3]
  }
]
// 回传方式
export const CallbackModes = [
  {
    value: 1,
    text: 'H5调起',
    channel: [3, 5, 8, 14, 15],
    client: [1, 4],
    platform: [3, 4]
  },
  {
    value: 2,
    text: '组件调起',
    channel: [3, 5, 8, 14, 15, 16, 17],
    client: [1, 3],
    platform: [2, 3]
  },
  {
    value: 3,
    text: '企业微信',
    channel: [3],
    client: [1],
    platform: [3]
  }
]
// 推广渠道
export const IAAChannelTypes = [
  {
    value: 1,
    text: '巨量引擎',
    platform: [1]
  },
  {
    value: 3,
    text: '广点通',
    platform: [3]
  },
  {
    value: 4,
    text: '其他',
    platform: [1, 3]
  }
]
// 回传方式
export const IAACallbackModes = [
  {
    value: 1,
    text: 'H5调起',
    channel: [4],
    platform: [1, 3]
  },
  {
    value: 2,
    text: '组件调起',
    channel: [1, 3],
    platform: [1, 3]
  }
]
// 密码的默认展示规则（此规则已与后端约定，请勿轻易修改）
export const PasswordRule = '******'
// 微信广告类型
export const AdvTypesWx = [
  {
    value: 1,
    text: '激励广告'
  },
  // {
  //   value: 2,
  //   text: '原生模板广告'
  // },
  // {
  //   value: 3,
  //   text: 'banner广告'
  // },
  // {
  //   value: 4,
  //   text: '插屏广告'
  // }
]
// 看广告时间间隔
export const AdvTimeInterval = [
  {
    value: 0,
    text: '0分钟'
  },
  {
    value: 5,
    text: '5分钟'
  },
  {
    value: 10,
    text: '10分钟'
  }
]
// 百度回传方式
export const BDCallbackModes = [
  {
    value: 1,
    text: '付费观剧'
  },
  {
    value: 2,
    text: '付费阅读'
  }
]
// 巨量回传方式
export const TTCallbackModes = [
  {
    value: 1,
    text: '1.0',
    channel: [1],
    client: [2, 1, 4, 5],
    platform: [1, 3, 4, 5]
  },
  {
    value: 2,
    text: '1.0（资产化）',
    channel: [1],
    client: [2, 1, 4, 5],
    platform: [1, 3, 4, 5]
  },
  {
    value: 3,
    text: '2.0',
    channel: [1],
    client: [2, 1, 4, 5],
    platform: [1, 3, 4, 5]
  },
  {
    value: 4,
    text: '2.0（资产化）',
    channel: [1],
    client: [2, 1, 4, 5],
    platform: [1, 3, 4, 5]
  },
  {
    value: 5,
    text: '星图',
    channel: [1],
    client: [2, 1, 4, 5],
    platform: [1, 3, 4, 5]
  }
]
// 项目属性
export const ProjectAttrs = [
  {
    value: 1,
    text: '自营'
  },
  {
    value: 2,
    text: '分销混用'
  },
  {
    value: 3,
    text: '分销独用'
  }
]
// 分销商小程序属性
export const ResellerAttrs = [
  {
    value: 1,
    text: '混用'
  },
  {
    value: 2,
    text: '独用'
  }
]
// 回传类型
export const CallbackTypes = [
  {
    value: 1,
    text: '全部回传'
  },
  {
    value: 2,
    text: '只传首充',
    msg: '只回传用户的首笔充值。'
  },
  {
    value: 3,
    text: '按订单回传',
    msg: '当产生新订单时，根据当前总订单的回传比例来判断该订单是否回传。'
  },
  {
    value: 5,
    text: '按用户回传',
    msg: '当新用户产生首充时，根据当前总用户的回传比例来判断该用户是否回传。一旦用户首充不回传，复充也不回传。'
  },
  // {
  //   value: 4,
  //   text: '按订单扣用户',
  //   msg: '当新用户产生首充时，根据当前总订单的回传比例来判断该用户是否回传。一旦用户首充不回传，复充也不回传。'
  // }
]
// H5投放链路
export const DropLinkTypes = [
  {
    value: 1,
    text: '企微客服'
  },
  {
    value: 2,
    text: '直投'
  },
]
// 推送消息类型
export const PushMsgTypes = [
  {
    value: 1,
    text: 'H5链接'
  },
  {
    value: 2,
    text: '微信小程序'
  },
  {
    value: 3,
    text: '文本'
  },
  {
    value: 4,
    text: '图片'
  },
  // {
  //   value: 5,
  //   text: '客服名片'
  // }
]
// 推送消息状态
export const PushMsgStatus = [
  {
    value: 1,
    text: '待推送'
  },
  {
    value: 2,
    text: '已推送'
  },
  {
    value: 3,
    text: '取消推送'
  },
  {
    value: 4,
    text: '推送失败'
  }
]
// 客服
export const CustomerService = [
  {
    value: 'MengYu',
    text: '追剧小帮手-小贝'
  },
  {
    value: 'ffe4c14bad5702e4cf25e4e708af8f32',
    text: '追剧小帮手-小琪'
  },
  {
    value: 'JinQing',
    text: '追剧小帮手-小诗'
  },
  // {
  //   value: 'ChengYe',
  //   text: '福利官云云'
  // },
  {
    value: 'HaiXing',
    text: '追剧小帮手-小鲸'
  },
  {
    value: 'mn',
    text: '追剧小帮手-小星'
  },
  {
    value: 'LiChunWei',
    text: '追剧小帮手-小春'
  },
  {
    value: 'chen',
    text: '追剧小帮手-小琦'
  },
  {
    value: 'HengXing',
    text: '追剧小帮手-小东'
  },
  {
    value: 'YuanCheng',
    text: '追剧小帮手-小远'
  },
  {
    value: 'AiYaAiYaAi',
    text: '追剧小帮手-成成'
  },
  {
    value: 'BaiShiKeAi',
    text: '追剧小帮手-小团'
  },
  {
    value: 'YiLan',
    text: '追剧小帮手-小玉'
  },
  {
    value: 'BaiXiong',
    text: '追剧小帮手-小熊'
  },
  {
    value: 'd41d8cd98f00b204e9800998ecf8427e01',
    text: '追剧小帮手-小凡'
  },
  {
    value: 'angeline',
    text: '追剧小帮手-小洁'
  },
  {
    value: 'ffe4c14bad5702e4cf25e4e708af8f32',
    text: '追剧小帮手-小琪'
  },
  {
    value: 'SongXiaoBaoNuLiBanZhuanKuaiLeBan',
    text: '追剧小帮手-小杨'
  },
  {
    value: 'GaoLeng',
    text: '追剧小帮手-小怡'
  },
  {
    value: 'ZhiAi',
    text: '追剧小帮手-小响'
  },
  {
    value: 'y',
    text: '追剧小帮手-小悦'
  },
  {
    value: 'ShuYeYeYe',
    text: '追剧小帮手-小路'
  },
  {
    value: 'chenchen',
    text: '(外部)追剧小帮手-小晨'
  },
  {
    value: 'Guo',
    text: '士国'
  }
]
// 金额类型
export const RechargeTemplateTypes = [
  {
    value: 1,
    text: '小额'
  },
  {
    value: 2,
    text: '中额'
  },
  {
    value: 3,
    text: '大额'
  }
]
// 模版类型
export const TemplateTypes = [
  {
    value: 1,
    text: '默认模板'
  },
  {
    value: 2,
    text: '复充模板'
  }
]
// 充值方式
export const RechargeMethods = [
  {
    value: 1,
    text: '增加 +'
  },
  {
    value: 2,
    text: '扣除 -'
  }
]
// 充值类型
export const RechargeTypes = [
  {
    value: 'coin',
    text: '虚拟币'
  },
  {
    value: 'vip',
    text: '会员'
  }
]
// 是否首充
export const FirstChargeTypes = [
  {
    value: -1,
    text: '否'
  },
  {
    value: 1,
    text: '是'
  }
]
// 回传状态
export const ReturnStatus = [
  {
    value: -1,
    text: '失败'
  },
  {
    value: 1,
    text: '成功'
  }
]
// 分成方式
export const DivideTypes = [
  {
    value: 1,
    text: '按充值'
  },
  {
    value: 2,
    text: '按消耗'
  }
]
// 达人素材
export const TalentMaterials = [
  {
    value: 1,
    text: '1-10集'
  },
  {
    value: 2,
    text: '1-20集'
  },
  {
    value: 3,
    text: '1-30集'
  },
  {
    value: 4,
    text: '1-40集'
  },
  {
    value: 5,
    text: '1-50集'
  }
]
// 前端引导 - 抖音
export const TTGuidance = [
  {
    value: 1,
    text: '关注抖音号'
  },
  {
    value: 2,
    text: '加入粉丝群'
  },
  {
    value: 3,
    text: '联系客服'
  },
  {
    value: 4,
    text: '播放页 - 关闭付费面板'
  },
  {
    value: 20,
    text: '福利-免费看剧模块'
  }
]

// 前端引导 - 快手
export const KSGuidance = [
  {
    value: 1,
    text: '关注服务号'
  },
  {
    value: 2,
    text: '播放页 - 关闭付费面板'
  },
  {
    value: 20,
    text: '福利-免费看剧模块'
  }
]

// 前端引导 - 微信
export const WXGuidance = [
  {
    value: 1,
    text: '首页-关注引导'
  },
  {
    value: 2,
    text: '播放页-关注引导'
  },
  {
    value: 3,
    text: '付费成功'
  },
  {
    value: 4,
    text: '联系客服'
  },
  {
    value: 5,
    text: '播放页 - 关闭付费面板'
  },
  {
    value: 20,
    text: '福利-免费看剧模块'
  }
]

// 分销管理 - 业务归属类型
export const BusinessOwners = [
  {
    value: 1,
    text: '分销'
  },
  {
    value: 2,
    text: '自投'
  },
  {
    value: 3,
    text: '自运营'
  },
  {
    value: 4,
    text: '达人团长'
  }
]

// 自定义充值模版 - 会员价格限制
export const RechargeTemplateVipCheck = [
  {
    min: 1, // 最小天数
    max: 1, // 最大天数
    num: 0 // 日均价不小于，等于大于即可
  },
  {
    min: 2,
    max: 3,
    num: 0
  },
  {
    min: 4,
    max: 7,
    num: 0
  },
  {
    min: 8,
    max: 30,
    num: 0
  },
  {
    min: 31,
    max: 90,
    num: 0
  },
  {
    min: 91,
    max: 180,
    num: 0
  },
  {
    min: 181,
    max: -1, // 小于 0 ，就是不限制
    num: 0
  }
]

// 片库 - 剧情类型
export const PlotTypes = [
  {
    value: 185437,
    text: '男频'
  },
  {
    value: 185438,
    text: '女频'
  }
]
// iaa推广链接 - 关键事件回传方式
export  const IAACallbackTypes = [
  {
    value: 1,
    text: '不回传'
  },
  {
    value: 2,
    text: '满足所有条件回传'
  },
  {
    value: 3,
    text: '满足任意条件回传'
  }
]

// 链路来源类型
export const LinkSourceTypes = [
  {
    value: 1,
    text: 'IAP链路'
  },
  {
    value: 2,
    text: 'IAA链路'
  }
]

// 礼品类型
export const GiftTypes = [
  {
    value: 1,
    text: '会员'
  },
  {
    value: 2,
    text: '话费'
  }
]

// 礼品状态类型
export const GiftStatusTypes = [
  {
    value: 1,
    text: '处理中'
  },
  {
    value: 2,
    text: '兑换成功'
  },
  {
    value: 3,
    text: '兑换失败'
  },
  {
    value: 4,
    text: '充值失败'
  }
]

// 活动类型
export const ActivityTypes = [
  {
    value: 1,
    text: '购币优惠'
  },
  {
    value: 2,
    text: '会员优惠'
  },
  {
    value: 3,
    text: '免费赠币'
  },
  {
    value: 4,
    text: '免费领会员'
  }
]